import React, {useEffect, useState} from "react";
import csv from "csvtojson"
import * as XLSX from 'xlsx';
import axios from "axios";
import SearchSelect from 'react-select';
import {useDispatch, useSelector} from "react-redux";
import {showAlert} from "../../providers/AlertProvider";
import {Card, CardTitle} from "../../components/ui/Card";
import {fetchAttributes, fetchQuestionBanks} from "../../redux/actions/HomeActions";
import {Select} from "../../components/ui/Select";
import Loading from "../../components/ui/Loading";
import {Button} from "../../components/ui/Button";

const UploadFreemiumData = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchQuestionBanks())
        dispatch(fetchAttributes('property'))
    }, []);

    const [questionsFile, setQuestionsFile] = useState(null)
    const [answersFile, setAnswersFile] = useState(null)
    const {questionBanks, success} = useSelector((state) => state.questionBankListReducer);
    const {attributes} = useSelector((state) => state.attributeListReducer);
    const [questionBank, setQuestionBank] = useState(null);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [loading, setLoading] = useState(false);
    const fetchCategories = async () => {
        const {data} = await axios.get(`/admin/quiz/category/all`)
        setCategories(data)
    }
    useEffect(() => {
        fetchCategories()
    }, []);
    const handleInputChange = (property_title_id, property_id) => {
        setSelectedProperties(prevSelectedProperties => {
            const existingIndex = prevSelectedProperties.findIndex(item => item.property_title_id === property_title_id);

            if (existingIndex >= 0) {
                // Update the existing entry with the new property_id
                const updatedProperties = [...prevSelectedProperties];
                updatedProperties[existingIndex] = {
                    ...updatedProperties[existingIndex],
                    property_id: parseInt(property_id)
                };
                return updatedProperties;
            } else {
                // Add a new entry
                return [...prevSelectedProperties, {property_title_id, property_id: parseInt(property_id)}];
            }
        });
    };

    const [formData, setFormData] = useState({
        questionsWithOptions: [], answers: [],
    })

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const id = event.target.id
        if (id === "questions_with_options_uploader") {
            setQuestionsFile(file)
        } else if (id === "answers_uploader") {
            setAnswersFile(file)
        }
        if (file) {
            if (file.name.slice(-5) === ".xlsx") {
                xlsxFileRead(file, id)
            } else if (file.name.slice(-4) === ".csv") {
                csvFileRead(file, id)
            } else {
                console.log("File Type not Supported")
            }
        }
    }
    const uploadData = async () => {
        try {
            setLoading(true)
            formData.question_bank_id = questionBank.id
            formData.category_id = selectedCategory.id
            formData.subcategory_id = selectedSubCategory.id
            formData.properties = selectedProperties
            await axios.post('/admin/freemium/data/add', formData);
            showAlert("success", "Data Uploaded Successfully")
        } catch (error) {
            showAlert("error", error.message)
        } finally {
            setLoading(false)
        }
    }
    const csvFileRead = (file, id) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const csvData = e.target.result;
            const jsonData = await csv().fromString(csvData);
            setData(jsonData, id)
        };
        reader.readAsText(file);
    }
    const xlsxFileRead = (file, id) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, {type: 'array'});

            const sheetName = workbook.SheetNames[0]; // Assuming the first sheet is the one to convert
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
            setData(jsonData, id)
        }
        reader.readAsArrayBuffer(file);
        // Perform further operations with the converted JSON data
    }
    const setData = (data, id) => {
        if (id === "questions_with_options_uploader") {
            const modData = data.map(x => {
                const options = Object.values(x).filter(y => y !== x['Question'])
                return {question: x['Question'], options: options}
            })
            setFormData({...formData, questionsWithOptions: modData})
        } else if (id === "answers_uploader") {
            const optionMap = {};
            for (let i = 0; i < 26; i++) {
                const upperChar = String.fromCharCode(65 + i); // 'A' is 65 in ASCII
                const lowerChar = String.fromCharCode(97 + i); // 'a' is 97 in ASCII
                optionMap[upperChar] = i + 1;
                optionMap[lowerChar] = i + 1;
            }
            const modData = data.map(x => {
                const correctOptionChars = x["Answer"].slice(0, 3);

                // Find the correct option character in the first three characters
                const correctOptionChar = Object.keys(optionMap).find(char => correctOptionChars.includes(char));

                // Get the integer value from the optionMap
                const correctOption = optionMap[correctOptionChar];
                return {questionNumber: x['Question Number'], answer: x['Answer'], correctOption: correctOption}
            })
            setFormData({...formData, answers: modData})
        }
    }
    const handleSelectedQuestionBank = (selected) => {
        setSelectedProperties([])
        setQuestionBank(questionBanks.find(x => x.id === Number(selected.value)))
    }
    const handleSelectedCategory = (selected) => {
        setSelectedCategory(categories.find(x => x.id === Number(selected.value)))
    }
    const handleSelectedSubCategory = (selected) => {
        setSelectedSubCategory(selectedCategory.subcategories.find(x => x.id === Number(selected.value)))
    }
    return (success && <React.Fragment>
        <Card>
            <CardTitle>Upload Data</CardTitle>
            <SearchSelect className="mt-1 w-full border border-gray-500"
                          isSearchable={true}
                          options={[...questionBanks.map(x => ({
                              value: x.id, label: x.name
                          }))]}
                          onChange={handleSelectedQuestionBank} // Set the selected option as the new state
                          placeholder={'Question Banks'}/>
            {questionBank?.property_titles.map((field) => (<Select
                id={field.id}
                value={selectedProperties.find(x => x.type_id === field.id)?.value_id}
                onChange={(e) => handleInputChange(field.id, e.target.value)}
                label={field.value}>
                <option value="">Select a Value</option>
                {attributes.map((option) => (<option key={option.id} value={option.id}>
                    {option.name}
                </option>))}
            </Select>))}

            <SearchSelect className="mt-1 w-full border border-gray-500"
                          isSearchable={true}
                          options={[...categories.map(x => ({
                              value: x.id, label: x.name
                          }))]}
                          onChange={handleSelectedCategory} // Set the selected option as the new state
                          placeholder={'Select a Category'}/>

            {selectedCategory !== null && <SearchSelect className="mt-1 w-full border border-gray-500"
                           isSearchable={true}
                           options={[...selectedCategory.subcategories.map(x => ({
                               value: x.id, label: x.name
                           }))]}
                           onChange={handleSelectedSubCategory} // Set the selected option as the new state
                           placeholder={'Select a SubCategory'}/>}

            <span className="text-lg text-gray-600">Upload Question With Options</span>
            <div>
                <label htmlFor="questions_with_options_uploader"
                       className="flex cursor-pointer flex-col justify-center items-center rounded bg-gray-50 border-dashed border-2 py-8 m-2">
                    <span className="material-symbols-rounded text-6xl my-2 text-gray-600">cloud_upload</span>
                    <span className="text-lg text-gray-600">Supported format: csv, xlsx</span>
                    <span
                        className="text-lg text-gray-600 border-dashed border-gray-500 border-[1px] my-2 mt-3 px-2 py-1 rounded">{questionsFile ? questionsFile.name : "Browse File"}</span>

                </label>
                <input id="questions_with_options_uploader" type="file" accept=".csv, .xlsx" className="hidden"
                       onChange={handleFileUpload}/>
            </div>

            <span className="text-lg text-gray-600">Upload Answers</span>
            <div>
                <label htmlFor="answers_uploader"
                       className="flex cursor-pointer flex-col justify-center items-center rounded bg-gray-50 border-dashed border-2 py-8 m-2">
                    <span className="material-symbols-rounded text-6xl my-2 text-gray-600">cloud_upload</span>
                    <span className="text-lg text-gray-600">Supported format: csv, xlsx</span>
                    <span
                        className="text-lg text-gray-600 border-dashed border-gray-500 border-[1px] my-2 mt-3 px-2 py-1 rounded">{answersFile ? answersFile.name : "Browse File"}</span>
                </label>
                <input id="answers_uploader" type="file" accept=".csv, .xlsx" className="hidden"
                       onChange={handleFileUpload}/>
            </div>
            <Button onClick={uploadData}>{loading ? <Loading small={true}/> : "Upload Data"}</Button>
        </Card>
    </React.Fragment>)
}
export default UploadFreemiumData