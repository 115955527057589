import axios from "axios";
import {showAlert} from "../providers/AlertProvider";

export const axiosPost = async (url, data, typeName) => {
    try {
        const response = await axios.post(url, data);
        showAlert('success', typeName + " Added Successfully")
        return response.data
    } catch (e) {
        showAlert("error", e.response && e.response.data.detail ? e.response.data.detail : e.message)
        return null
    }
}
export function isBase64Image(data) {
    return data.startsWith("data:image/") && data.includes(";base64,");
}
export const axiosPatch = async (url, data, typeName) => {
    try {
        const response = await axios.patch(url, data);
        showAlert('success', typeName + " Updated Successfully")
        return response.data
    } catch (e) {
        showAlert("error", e.response && e.response.data.detail ? e.response.data.detail : e.message)
        return null
    }
}
export const axiosGet = async (url) => {
    try {
        const response = await axios.get(url);
        return response.data
    } catch (e) {
        showAlert("error", e.response && e.response.data.detail ? e.response.data.detail : e.message)
        return null
    }
}
