
export const FREEMIUM_SUBJECT_LIST_REQUEST = "FREEMIUM_SUBJECT_LIST_REQUEST"
export const FREEMIUM_SUBJECT_LIST_SUCCESS = "FREEMIUM_SUBJECT_LIST_SUCCESS"
export const FREEMIUM_SUBJECT_LIST_ERROR = "FREEMIUM_SUBJECT_LIST_ERROR"

export const FREEMIUM_CHAPTER_LIST_REQUEST = "FREEMIUM_CHAPTER_LIST_REQUEST"
export const FREEMIUM_CHAPTER_LIST_SUCCESS = "FREEMIUM_CHAPTER_LIST_SUCCESS"
export const FREEMIUM_CHAPTER_LIST_ERROR = "FREEMIUM_CHAPTER_LIST_ERROR"

export const FREEMIUM_QUIZ_BOOK_LIST_REQUEST = "FREEMIUM_QUIZ_BOOK_LIST_REQUEST"
export const FREEMIUM_QUIZ_BOOK_LIST_SUCCESS = "FREEMIUM_QUIZ_BOOK_LIST_SUCCESS"
export const FREEMIUM_QUIZ_BOOK_LIST_ERROR = "FREEMIUM_QUIZ_BOOK_LIST_ERROR"