import axios from "axios";
import React from "react";
import {useDispatch} from "react-redux";
import {fetchTitles, fetchQuestionBanks} from "../../redux/actions/HomeActions";
import {showAlert} from "../../providers/AlertProvider";

const MultipleSelection = ({tableName, selectedFlatRows}) => {
    const dispatch = useDispatch()
    const deleteRows = async (ids) => {
        const deleteOperations = {
            'properties': {
                url: '/admin/general/property/delete/bulk',
                successMessage: 'Attributes Deleted Successfully',
                fetchFunction: fetchTitles
            },
            'bank': {
                url: '/admin/general/question-bank/delete/bulk',
                successMessage: 'Question Banks Deleted Successfully',
                fetchFunction: fetchQuestionBanks
            },
        };

        const operation = Object.entries(deleteOperations).find(([key,]) => tableName.toLowerCase().includes(key));

        if (operation) {
            const [_, {url, successMessage, fetchFunction}] = operation;

            try {
                if (url.includes("pages")) {
                    await axios.post(url, {slugs: selectedFlatRows.map(row => row.original.slug.slice(1))});
                } else {
                    await axios.post(url, {ids: ids});
                }
                showAlert("success", successMessage);
                await dispatch(fetchFunction());
            } catch (error) {
                showAlert("error", error.message);
            }
        }
    };

    return (
        <div className="flex justify-between items-center px-2 py-2 rounded bg-red-100 w-full my-3">
                        <span
                            className="text-orange-400">{selectedFlatRows.length} {selectedFlatRows.length === 1 ? "row" : "rows"} selected</span>
            <div onClick={() => deleteRows(selectedFlatRows.map(row => row.original.id))}
                 className="flex items-center text-sm border-[1px] px-3 py-2 cursor-pointer rounded text-white bg-red-500 font-light">
                <span className="material-symbols-rounded mr-0.5 font-thin">delete</span>
                <span>Delete</span>
            </div>
        </div>
    )
}
export default MultipleSelection