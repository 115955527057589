import React, {useEffect, useState} from "react";
import SearchSelect from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {fetchAttributes, fetchQuestionBanks} from "../../redux/actions/HomeActions";
import axios from "axios";
import {showAlert} from "../../providers/AlertProvider";
import {Card, CardTitle} from "../../components/ui/Card";
import {Select} from "../../components/ui/Select";
import Loading from "../../components/ui/Loading";

const DeleteDatabase = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchQuestionBanks())
        dispatch(fetchAttributes('property'))
        fetchUploadedDatabases()
    }, []);
    const {questionBanks, success} = useSelector((state) => state.questionBankListReducer);
    const {attributes} = useSelector((state) => state.attributeListReducer);
    const [questionBank, setQuestionBank] = useState(null);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [loading, setLoading] = useState(false);
    const [databases, setDatabases] = useState([]);
    const [selectedDatabase, setSelectedDatabase] = useState(null);

    const handleInputChange = (property_title_id, property_id) => {
        setSelectedProperties(prevSelectedProperties => {
            const existingIndex = prevSelectedProperties.findIndex(item => item.property_title_id === property_title_id);

            if (existingIndex >= 0) {
                // Update the existing entry with the new property_id
                const updatedProperties = [...prevSelectedProperties];
                updatedProperties[existingIndex] = {
                    ...updatedProperties[existingIndex], property_id: parseInt(property_id)
                };
                return updatedProperties;
            } else {
                // Add a new entry
                return [...prevSelectedProperties, {property_title_id, property_id: parseInt(property_id)}];
            }
        });
    };

    const handleSelectedQuestionBank = (selected) => {
        setSelectedProperties([])
        setQuestionBank(questionBanks.find(x => x.id === Number(selected.value)))
    }
    const fetchUploadedDatabases = () => {
        setLoading(true);
        axios.get(`/admin/premium/database/all`).then(res => {
            setDatabases(res.data);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            showAlert("error", err.message);
        })
    }
    const deleteDatabase = (id) => {
        setLoading(true);
        setSelectedDatabase(id)
        axios.delete(`/admin/premium/database/delete/${id}`).then(res => {
            fetchUploadedDatabases()
            setLoading(false);
            showAlert('success', 'Database deleted successfully')
        }).catch(err => {
            setLoading(false);
            showAlert("error", err.message);
        })
    }
    return (success && <form>
        <Card>
            <CardTitle>Upload SQLite Database</CardTitle>
            <SearchSelect className="mt-1 w-full border border-gray-500"
                          isSearchable={true}
                          options={[...questionBanks.map(x => ({
                              value: x.id, label: x.name
                          }))]}
                          onChange={handleSelectedQuestionBank} // Set the selected option as the new state
                          placeholder={'Question Banks'}/>
            {questionBank?.property_titles.map((field) => (<Select
                id={field.id}
                value={selectedProperties.find(x => x.type_id === field.id)?.value_id}
                onChange={(e) => handleInputChange(field.id, e.target.value)}
                label={field.value}>
                <option value="">Select a Value</option>
                {attributes.map((option) => (<option key={option.id} value={option.id}>
                    {option.name}
                </option>))}
            </Select>))}
            <div className="w-full mx-auto mt-5">
                {databases.map(database => (selectedDatabase === database.id && loading ? <Loading small={true}/> : <div
                    className="flex justify-between items-center p-4 mb-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                    <span className="text-lg text-gray-800">{database.path}</span>
                    <span onClick={() => deleteDatabase(database.id)}
                          className="text-red-500 cursor-pointer text-2xl transform transition-transform duration-200 hover:scale-110 active:scale-90 material-symbols-rounded">delete</span>
                </div>))}
            </div>
        </Card>
    </form>)
}
export default DeleteDatabase