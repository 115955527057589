import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {faArrowLeft, faFile, faFolder, faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Downloads.css";
import axios from "axios";
import {showAlert} from "../../providers/AlertProvider";
import Loading from "../../components/ui/Loading";

const Downloads = ({setDownloadPath}) => {
    const location = useLocation();
    const [downloads, setDownloads] = useState({directories: [], files: [], relative_path: "", current_directory: ""});
    const {directories, files, relative_path, current_directory} = downloads;
    const [loading, setLoading] = useState(false);
    const [filteredFiles, setFilteredFiles] = useState(files)
    const [filteredFolders, setFilteredFolders] = useState(directories)
    const [searchValue, setSearchValue] = useState("");
    const [selectedFile, setSelectedFile] = useState("");

    const fetchDownloads = (path) => {
        setLoading(true);
        axios.get(`/admin/premium/downloads${path ? "?path=" + path : ""}`).then(res => {
            setDownloads(res.data);
            setFilteredFolders(res.data.directories);
            setFilteredFiles(res.data.files);
            setSearchValue('')
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            showAlert("error", err.message);
        })
    }


    useEffect(() => {
        fetchDownloads()
    }, [location]);
    useEffect(() => {
        setDownloadPath(selectedFile)
    }, [selectedFile]);
    const getPath = () => relative_path.split("/").slice(1).join("/");
    const handleFolderClick = (folder) => fetchDownloads(folder.path);

    const goBack = () => {
        const result = getPath().replace(current_directory, '').replace(/(^\/+|\/+$)/g, '');
        fetchDownloads(result);
    }
    const handleSearch = () => {
        setFilteredFiles(files.filter(x => x.name.toLowerCase().includes(searchValue.toLowerCase())));
        setFilteredFolders(directories.filter(x => x.name.toLowerCase().includes(searchValue.toLowerCase())));
    }

    return (<>
        <div className="path-header flex justify-between px-5">
            <div className="grid grid-cols-1 lg:flex lg:grid-cols-3 gap-2">
                <button type="button"
                        className="bg-white text-black rounded-full px-3 h-9 text-sm hover:bg-black hover:text-white  transition-all"
                        onClick={goBack}>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                </button>
            </div>
            <div
                className={`flex mb-2 mx-1 max-w-[50%] justify-center items-center transition-all duration-500`}>
                <input type="text" id="searchBar" value={searchValue}
                       onChange={(e) => setSearchValue(e.target.value)}
                       className="py-2 px-3 text-md rounded-bl-md rounded-tl-md w-full text-black outline-none border border-gray-200"
                       placeholder="Search"/>
                <button onClick={handleSearch} type={"button"}
                        className="text-md bg-white rounded-br-md rounded-tr-md -ml-0.5 cursor-pointer border border-gray-200 font-light hover:font-normal py-2 px-4 text-gray-600">
                    <FontAwesomeIcon icon={faSearch}/></button>
            </div>
        </div>
        <div className="path-header">{relative_path}</div>
        <section className="media-container">
            {loading ? <Loading height={"50vh"}/> : <>
                {filteredFolders.map((directory, index) => {
                    const filePath = relative_path + "/" + directory.name
                    return (<div onClick={() => handleFolderClick(directory)}
                                 key={index} className="media-item relative">
                        <FontAwesomeIcon icon={faFolder} className="media-icon folder"/>
                        <span className="media-name">{directory.name}</span>
                    </div>)
                })}
                {filteredFiles.map((file, index) => {
                    const filePath = relative_path + "/" + file.name
                    const content = <div key={index}
                                         onClick={() => setSelectedFile(filePath)}
                                         className="media-item relative">
                        {(selectedFile === filePath) &&
                            <div className="absolute top-2 left-0 w-full h-32 bg-blue-700 bg-opacity-30 rounded"></div>}
                        <FontAwesomeIcon icon={faFile} className="media-icon file"/>
                        <span className="media-name">{file.name}</span>
                    </div>
                    return (content)
                })}
                {directories.length === 0 && files.length === 0 && filteredFiles === files && filteredFolders === directories ?
                    <div className='h-[50vh] flex items-center justify-center'>
                        <span>This Folder is Empty!</span>
                    </div> : filteredFolders.length === 0 && filteredFiles.length === 0 &&
                    <div className='h-[50vh] flex items-center justify-center'>
                        <span>No search result was found!</span>
                    </div>}
            </>}
        </section>


    </>)
}

export default Downloads;

