import React from "react";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {showAlert} from "../../providers/AlertProvider";


const AddMultipleObjects = ({selectedObjects, objectsArray, setSelectedObjects, label}) => {
    const addObject = (selected) => {
        if (selectedObjects.some(x => x.value === selected.value)) {
            showAlert('error', 'Already Added')
        } else {
            setSelectedObjects(prevState => [...prevState, selected])
        }
    }
    const deleteObject = (selected) => {
        setSelectedObjects(selectedObjects.filter(x => x.value !== selected.value))
    }
    return (
        <div className="w-full flex flex-col gap-2">
            <span>{label}</span>
            <Select className="mt-1 w-full border border-gray-500"
                    isSearchable={true}
                    options={[...objectsArray.map(x => ({
                        value: x.id, label: x.name
                    }))]}
                    onChange={addObject}
            />
            <div className="flex gap-2 mt-2 flex-wrap">
                {selectedObjects.map(x => (
                    <span
                        className={'px-4 py-2 bg-gray-100 text-black rounded-lg border-black border-[1px]' +
                            'transition-all duration-500'}>
                                {x.label} <FontAwesomeIcon onClick={() => deleteObject(x)}
                                                           className="hover:text-red-800 cursor-pointer"
                                                           icon={faCircleXmark}/></span>
                ))}
            </div>
        </div>
    )
}
export default AddMultipleObjects