import React, {useEffect, useState} from "react";
import SearchSelect from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {fetchAttributes, fetchQuestionBanks} from "../../redux/actions/HomeActions";
import axios from "axios";
import {showAlert} from "../../providers/AlertProvider";
import {Button} from "../../components/ui/Button";
import {Card, CardTitle} from "../../components/ui/Card";
import {Select} from "../../components/ui/Select";
import Loading from "../../components/ui/Loading";
import Downloads from "./Downloads";

const UploadDatabase = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchQuestionBanks())
        dispatch(fetchAttributes('property'))
    }, []);
    const [databasePath, setDatabasePath] = useState("")
    const {questionBanks, success} = useSelector((state) => state.questionBankListReducer);
    const {attributes} = useSelector((state) => state.attributeListReducer);
    const [questionBank, setQuestionBank] = useState(null);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (property_title_id, property_id) => {
        setSelectedProperties(prevSelectedProperties => {
            const existingIndex = prevSelectedProperties.findIndex(item => item.property_title_id === property_title_id);

            if (existingIndex >= 0) {
                // Update the existing entry with the new property_id
                const updatedProperties = [...prevSelectedProperties];
                updatedProperties[existingIndex] = {
                    ...updatedProperties[existingIndex],
                    property_id: parseInt(property_id)
                };
                return updatedProperties;
            } else {
                // Add a new entry
                return [...prevSelectedProperties, {property_title_id, property_id: parseInt(property_id)}];
            }
        });
    };

    const submitDatabase = async (event) => {
        event.preventDefault()
        if (databasePath === "") {
            showAlert("error", "Please Select Database")
        } else if (databasePath.slice((databasePath.lastIndexOf(".") - 1 >>> 0) + 2) !== "db") {
            showAlert("error", "Please Select a valid Database")
        } else {
            setLoading(true)
            const uploadData = {databasePath, questionBankId: questionBank.id, properties: selectedProperties}
            try {
                const {data} = await axios.post("/admin/premium/database/add", uploadData);
                showAlert("success", "Database uploaded successfully");
                setLoading(false);
            } catch (e) {
                if (e.message.includes("etwork")) {
                    showAlert('error', "Retrying due to heavy load on server");
                    await submitDatabase(event);
                    console.log(e.message)
                } else {
                    showAlert('error', e.message);
                    setLoading(false);
                }
            }
        }

    }
    const handleSelectedQuestionBank = (selected) => {
        setSelectedProperties([])
        setQuestionBank(questionBanks.find(x => x.id === Number(selected.value)))
    }
    return (success &&
        <form onSubmit={submitDatabase}>
            <Card>
                <CardTitle>Upload SQLite Database</CardTitle>
                <SearchSelect className="mt-1 w-full border border-gray-500"
                              isSearchable={true}
                              options={[...questionBanks.map(x => ({
                                  value: x.id, label: x.name
                              }))]}
                              onChange={handleSelectedQuestionBank} // Set the selected option as the new state
                              placeholder={'Question Banks'}/>
                {questionBank?.property_titles.map((field) => (
                    <Select
                        id={field.id}
                        value={selectedProperties.find(x => x.type_id === field.id)?.value_id}
                        onChange={(e) => handleInputChange(field.id, e.target.value)}
                        label={field.value}>
                        <option value="">Select a Value</option>
                        {attributes.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </Select>
                ))}
                {databasePath !== null && <div>
                    Selected Database: {databasePath.name}
                </div>}
                <Downloads setDownloadPath={setDatabasePath}/>
                {databasePath && <div>Selected Database: {databasePath}</div>}
                <Button type="submit">{loading ? <Loading small={true}/> : "Upload Database"}</Button>
            </Card>
        </form>
    )
}
export default UploadDatabase