import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import TableComponent from "../../components/TableComponentFiles/TableComponent";
import {Card, CardTitle} from "../../components/ui/Card";
import {Input} from "../../components/ui/Input";
import {Button} from "../../components/ui/Button";
import {axiosPatch, axiosPost} from "../../functions/utilityFucntions";
import {useDispatch, useSelector} from "react-redux";
import {fetchAttributes} from "../../redux/actions/HomeActions";

const Attributes = ({typeName}) => {
    const {id} = useParams()
    const url = `/admin/quiz/${typeName.toLowerCase()}`
    const [attribute, setAttribute] = useState("")
    const dispatch = useDispatch()
    const {attributes, success} = useSelector((state) => state.attributeListReducer);

    useEffect(() => {
        dispatch(fetchAttributes(typeName))
    }, [url]);


    useEffect(() => {
        if (id && success) {
            setAttribute(attributes.find(x => x.id === Number(id))?.name)
        } else {
            setAttribute("")
        }
    }, [id, attributes, success]);

    const submitAttribute = async (e) => {
        e.preventDefault()
        if (id) {
            await axiosPatch(`${url}/update/${id}/`, {name: attribute}, typeName)
        } else {
            const data = await axiosPost(`${url}/add/`, {name: attribute}, typeName)
            if (data !== null) {
                setAttribute("")
            }
        }
        dispatch(fetchAttributes(typeName))
    }

    return (<>
        <form onSubmit={submitAttribute}>
            <Card>
                <CardTitle>{id ? "Update" : "Add"} {typeName}</CardTitle>
                <Input type="text" id="name" value={attribute} required={true}
                       label="Name" onChange={(e) => setAttribute(e.target.value)}/>
                <Button type="submit">{`${id ? "Update" : "Add"} ${typeName}`}</Button>
            </Card>
        </form>
        {attributes.length > 0 && !id && <TableComponent TableData={attributes} tableName={typeName + " List"}/>}
    </>)
}
export default Attributes