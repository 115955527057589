import React, {useEffect, useState} from "react";
import {Card, CardTitle} from "../../components/ui/Card";
import {Button} from "../../components/ui/Button";
import {axiosGet, axiosPatch} from "../../functions/utilityFucntions";
import {Select} from "../../components/ui/Select";
import AddMultipleObjects from "../../components/ui/AddMultipleObjects";
import SearchSelect from "react-select";

const SubCategoriesOfCategory = () => {
    const url = `/admin/quiz/`
    const [subcategories, setSubcategories] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedSubCategories, setSelectedSubCategories] = useState([])
    const [categoryId, setCategoryId] = useState("")
    useEffect(() => {
        fetchCategories()
        fetchSubCategories()
    }, []);

    const fetchCategories = () => {
        axiosGet(url + "category/all/").then((data) => {
            setCategories(data)
        })
    }

    const fetchSubCategories = () => {
        axiosGet(url + "subcategory/all/").then((data) => {
            setSubcategories(data)
        })
    }

    const submitSubCategoriesOfCategory = async (e) => {
        e.preventDefault()
        await axiosPatch(`${url}category/update/subcategories/${categoryId}/`, {
            subcategory_ids: selectedSubCategories.map(x => {
                return x.value
            })
        }, "SubCategories Of Category")
        fetchCategories()
    }
    const handleCategoryChange = (e) => {
        const id = Number(e.target.value)
        setCategoryId(id)
        const filteredSubCategories = subcategories.filter(y => categories.find(x => x.id === id).subcategories.includes(y.id))
        setSelectedSubCategories(filteredSubCategories.map(x => {
            return {value: x.id, label: x.name}
        }))
    }
    const handleSelectedCategory = (selected) => {
        setCategoryId(Number(selected.value))
    }
    return (<>
        <form onSubmit={submitSubCategoriesOfCategory}>
            <Card>
                <CardTitle>Sub-Categories of Category</CardTitle>
                <SearchSelect className="mt-1 w-full border border-gray-500"
                              isSearchable={true}
                              options={[...categories.map(x => ({
                                  value: x.id, label: x.name
                              }))]}
                              onChange={handleSelectedCategory} // Set the selected option as the new state
                              placeholder={'Select a Category'}/>
                <AddMultipleObjects objectsArray={subcategories} selectedObjects={selectedSubCategories}
                                    setSelectedObjects={setSelectedSubCategories} label={"Sub-Categories"}/>

                <Button type="submit">Save</Button>
            </Card>
        </form>
    </>)
}
export default SubCategoriesOfCategory