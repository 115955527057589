import {
    USER_LIST_REQUEST,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    USER_UPDATE_ERROR,
    LOGIN_USER_REQUEST,
    REGISTER_USER_ERROR,
    USER_LIST_ERROR,
    USER_LIST_SUCCESS,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_DATA_REQUEST,
    USER_DATA_SUCCESS,
    USER_DATA_ERROR,
    AUTH_TRUE,
    AUTH_FALSE,
    REFRESH_USER_REQUEST,
    REFRESH_USER_SUCCESS,
    REFRESH_USER_ERROR,
    LOGOUT_USER_REQUEST,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_ERROR
} from "../constants/UserConstants";

export const usersListReducer = (state = {users: []}, action) => {
    switch (action.type) {
        case USER_LIST_REQUEST:
            return {loading: true, users: []}
        case USER_LIST_SUCCESS:
            return {loading: false, success: true, users: action.payload}
        case USER_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const userDataReducer = (state = {user: {}}, action) => {
    switch (action.type) {
        case USER_DATA_REQUEST:
            return {loading: true, user: {}}
        case USER_DATA_SUCCESS:
            return {loading: false, success: true, user: action.payload}
        case USER_DATA_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const registerUserReducer = (state = {message: {}}, action) => {
    switch (action.type) {
        case REGISTER_USER_REQUEST:
            return {loading: true, message: {}}
        case REGISTER_USER_SUCCESS:
            return {loading: false, success: true, message: action.payload}
        case REGISTER_USER_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const loginUserReducer = (state = {access_token: ""}, action) => {
    switch (action.type) {
        case LOGIN_USER_REQUEST:
            return {loading: true, access_token: ""}
        case LOGIN_USER_SUCCESS:
            return {loading: false, success: true, access_token: action.payload}
        case LOGIN_USER_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const refreshUserReducer = (state = {access_token: ""}, action) => {
    switch (action.type) {
        case REFRESH_USER_REQUEST:
            return {loading: true, access_token: ""}
        case REFRESH_USER_SUCCESS:
            return {loading: false, success: true, access_token: action.payload}
        case REFRESH_USER_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const logoutUserReducer = (state = {message: ""}, action) => {
    switch (action.type) {
        case LOGOUT_USER_REQUEST:
            return {loading: true, message: ""}
        case LOGOUT_USER_SUCCESS:
            return {loading: false, success: true, message: action.payload}
        case LOGOUT_USER_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const authReducer = (state = {auth: false}, action) => {
    switch (action.type) {
        case AUTH_TRUE:
            return true;
        case AUTH_FALSE:
            return false;
        default:
            return state;
    }
}