import React from "react";

export default function Loading({height = "70vh", small = false}) {
    if (small) height = "25px"
    return (
        <div className='w-full flex justify-center items-center' style={{height: height}}>
            <div
                className={`${small ? "w-5 h-5" : "w-16 h-16"} border-4 border-dashed rounded-full animate-spin dark:border-violet-400`}></div>
        </div>
    );
}