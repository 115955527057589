import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import TableComponent from "../../components/TableComponentFiles/TableComponent";
import {Card, CardTitle} from "../../components/ui/Card";
import {Input} from "../../components/ui/Input";
import {Button} from "../../components/ui/Button";
import {axiosPatch, axiosPost} from "../../functions/utilityFucntions";
import {useDispatch, useSelector} from "react-redux";
import {fetchTitles} from "../../redux/actions/HomeActions";

const AttributesTitles = ({typeName}) => {
    const {id} = useParams()
    const url = `/admin/quiz/${typeName.toLowerCase()}-title`
    const [title, setTitle] = useState("")
    const dispatch = useDispatch()
    const {titles, success} = useSelector((state) => state.titleListReducer);

    useEffect(() => {
        dispatch(fetchTitles(typeName))
    }, [url]);

    useEffect(() => {
        if (id && success) {
            setTitle(titles.find(x => x.id === Number(id))?.value)
        } else {
            setTitle("")
        }
    }, [id, titles, success]);

    const submitTitle = async (e) => {
        e.preventDefault()
        if (id) {
            await axiosPatch(`${url}/update/${id}/`, {value: title}, typeName + " Title")
        } else {
            const data = await axiosPost(`${url}/add/`, {value: title}, typeName + " Title")
            if (data !== null) {
                setTitle("")
            }
        }
        dispatch(fetchTitles(typeName))
    }
    return (<>
        <form onSubmit={submitTitle}>
            <Card>
                <CardTitle>{id ? "Update" : "Add"} {typeName} Title</CardTitle>
                <Input type="text" id="value" value={title} required={true}
                       label="Value" onChange={(e) => setTitle(e.target.value)}/>
                <Button type="submit">{`${id ? "Update" : "Add"} ${typeName} Title`}</Button>
            </Card>
        </form>
        {titles.length > 0 && !id && <TableComponent TableData={titles} tableName={typeName + " Titles"}/>}
    </>)
}
export default AttributesTitles