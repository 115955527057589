import React from "react";
import svgIcons from "./svgIcons.json";
import Select from "react-select";
import {FixedSizeList as List} from "react-window";

const height = 45; // Height of each option row
const IconListComponent = (props) => {
    const {options, children, maxHeight, getValue} = props;

    if (!children.length) {
        // No options to display
        return <div style={{padding: '10px', textAlign: 'center'}}>No icons found!</div>;
    }

    const [value] = getValue();
    const initialOffset = options.findIndex(option => option.value === value?.value) * height;

    return (
        <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={height}
            initialScrollOffset={initialOffset}
        >
            {({index, style}) => <div style={style}>{children[index]}</div>}
        </List>
    );
};


const IconsSelector = ({formData, setFormData}) => {
    const Option = ({innerProps, label}) => (
        <div {...innerProps} className="flex justify-between items-center">
            <span className="ml-2">{label}</span>
            <span className="font-light mr-2"> <div dangerouslySetInnerHTML={{__html: svgIcons[label]}}/></span>
        </div>
    );

    return (
        <div className="w-full">
            <label className="flex justify-between items-center" htmlFor="icon">
                <span>Icon (Out of {Object.keys(svgIcons).length} icons)</span>
                <div dangerouslySetInnerHTML={{__html: svgIcons[formData.icon_name]}}/>
            </label>
            <Select
                className="mt-1 w-full border border-gray-500"
                id="icon"
                isSearchable={true}
                options={[...Object.keys(svgIcons).map(x => ({value: getPathSvg(x), label: x}))]}
                components={{Option, MenuList: IconListComponent}}
                required={true}
                value={{label: formData.icon_name, value: formData.icon_svg}}
                onChange={(e) => setFormData((prevState) => ({
                    ...prevState,
                    icon_name: e.label, icon_svg: getPathSvg(e.label)
                }))}
            />
        </div>
    );
};

export default IconsSelector;

// The rest of your code remains unchanged.

export const getPathSvg = (icon) => {
    const svgString = svgIcons[icon]
    const dValueRegex = /<path[^>]*d="([^"]*)"[^>]*>/g;
    let matches;
    let combinedDValue = '';

    // Use a loop to extract all matches
    while ((matches = dValueRegex.exec(svgString)) !== null) {
        // If a match is found, add it to the combinedDValue string
        // You might also add a space or other delimiter if necessary
        combinedDValue += ' ' + matches[1];
    }

    // If no paths are found, return null; otherwise, trim the combinedDValue
    return combinedDValue === '' ? null : combinedDValue.trim();
};