import React from "react";

const SvgIcon = ({d, size, height = "24", width = "24", className = "text-black", ...props}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size ? size : width}
             height={size ? size : height} viewBox="0 -960 960 960"
             className={className + " fill-current"} style={{fill: "currentColor"}} {...props}>
            <path d={d}/>
        </svg>
    )
}
export default SvgIcon