import React, {useEffect} from "react";
import axios from "axios";
import {showAlert} from "../../providers/AlertProvider";
import SearchSelect from "react-select";
import csv from "csvtojson";
import * as XLSX from "xlsx";
import Loading from "../../components/ui/Loading";
import {Button} from "../../components/ui/Button";
import {Card, CardTitle} from "../../components/ui/Card";

const Colleges = () => {
    const [countries, setCountries] = React.useState([]);
    const [selectedCountry, setSelectedCountry] = React.useState(null);
    const [collegesDataFile, setCollegesDataFile] = React.useState(null);
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    useEffect(() => {
        fetchCountries();
    }, []);
    const fetchCountries = () => {
        axios.get(`/auth/countries`).then(res => {
            setCountries(res.data);
        }).catch(err => {
            showAlert("error", err.message);
        })
    }
    const handleCountrySelection = (selected) => {
        setSelectedCountry(countries.find(x => x.id === Number(selected.value)));
    }
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setCollegesDataFile(file)
        if (file) {
            if (file.name.slice(-5) === ".xlsx") {
                xlsxFileRead(file)
            } else if (file.name.slice(-4) === ".csv") {
                csvFileRead(file)
            } else {
                console.log("File Type not Supported")
            }
        }
    }
    const csvFileRead = (file) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const csvData = e.target.result;
            const jsonData = await csv().fromString(csvData);
            console.log(jsonData)
            setData(jsonData)
        };
        reader.readAsText(file);
    }
    const xlsxFileRead = (file) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, {type: 'array'});

            const sheetName = workbook.SheetNames[0]; // Assuming the first sheet is the one to convert
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
            setData(jsonData)

        }
        reader.readAsArrayBuffer(file);
        // Perform further operations with the converted JSON data
    }
    const uploadData = async () => {
        try {
            setLoading(true)
            console.log(data)
            await axios.post('/admin/general/colleges/add/', {collegesData: data, country_id: selectedCountry.id});
            showAlert("success", "Colleges Uploaded Successfully")
        } catch (error) {
            showAlert("error", error.message)
        } finally {
            setLoading(false)
        }
    }
    return (<Card>
        <CardTitle>Upload Bulk Colleges Data</CardTitle>
        <SearchSelect className="mt-1 w-full border border-gray-500"
                      isSearchable={true}
                      options={countries.map(country => ({value: country.id, label: country.name}))}
                      onChange={handleCountrySelection} // Set the selected option as the new state
                      placeholder={'Country'}/>
        {selectedCountry && <div>{selectedCountry.name}</div>}
        <div>
            <label htmlFor="colleges_data"
                   className="flex cursor-pointer flex-col justify-center items-center rounded bg-gray-50 border-dashed border-2 py-8 m-2">
                <span className="material-symbols-rounded text-6xl my-2 text-gray-600">cloud_upload</span>
                <span className="text-lg text-gray-600">Supported format: csv, xlsx</span>
                <span
                    className="text-lg text-gray-600 border-dashed border-gray-500 border-[1px] my-2 mt-3 px-2 py-1 rounded">{collegesDataFile ? collegesDataFile.name : "Browse File"}</span>

            </label>
            <input id="colleges_data" type="file" accept=".csv, .xlsx" className="hidden"
                   onChange={handleFileUpload}/>
        </div>
        <Button onClick={uploadData}>{loading ? <Loading small={true}/> : "Upload Colleges"}</Button>
    </Card>)
}
export default Colleges