import {FREEMIUM_CHAPTER_LIST_SUCCESS,FREEMIUM_QUIZ_BOOK_LIST_REQUEST,FREEMIUM_SUBJECT_LIST_SUCCESS,FREEMIUM_QUIZ_BOOK_LIST_ERROR,FREEMIUM_CHAPTER_LIST_REQUEST,FREEMIUM_SUBJECT_LIST_REQUEST,FREEMIUM_CHAPTER_LIST_ERROR,FREEMIUM_QUIZ_BOOK_LIST_SUCCESS,FREEMIUM_SUBJECT_LIST_ERROR
} from "../constants/FreemiumConstants";

export const freemiumSubjectsListReducer = (state = {subjects: []}, action) => {
    switch (action.type) {
        case FREEMIUM_SUBJECT_LIST_REQUEST:
            return {loading: true, subjects: []}
        case FREEMIUM_SUBJECT_LIST_SUCCESS:
            return {loading: false, success: true, subjects: action.payload}
        case FREEMIUM_SUBJECT_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const quizBookListReducer = (state = {quizBooks: []}, action) => {
    switch (action.type) {
        case FREEMIUM_QUIZ_BOOK_LIST_REQUEST:
            return {loading: true, quizBooks: []}
        case FREEMIUM_QUIZ_BOOK_LIST_SUCCESS:
            return {loading: false, success: true, quizBooks: action.payload}
        case FREEMIUM_QUIZ_BOOK_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const quizBookChapterListReducer = (state = {quizBookChapters: []}, action) => {
    switch (action.type) {
        case FREEMIUM_CHAPTER_LIST_REQUEST:
            return {loading: true, quizBookChapters: []}
        case FREEMIUM_CHAPTER_LIST_SUCCESS:
            return {loading: false, success: true, quizBookChapters: action.payload}
        case FREEMIUM_CHAPTER_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
