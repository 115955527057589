import {
    TITLE_LIST_ERROR,
    TITLE_LIST_SUCCESS,
    TITLE_LIST_REQUEST,
    QUESTION_BANK_LIST_ERROR,
    QUESTION_BANK_LIST_SUCCESS,
    QUESTION_BANK_LIST_REQUEST,
    ATTRIBUTE_LIST_REQUEST,
    ATTRIBUTE_LIST_SUCCESS,
    ATTRIBUTE_LIST_ERROR
} from "../constants/HomeConstants";

export const titleListReducer = (state = {titles: []}, action) => {
    switch (action.type) {
        case TITLE_LIST_REQUEST:
            return {loading: true, titles: []}
        case TITLE_LIST_SUCCESS:
            return {loading: false, success: true, titles: action.payload}
        case TITLE_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const attributeListReducer = (state = {attributes: []}, action) => {
    switch (action.type) {
        case ATTRIBUTE_LIST_REQUEST:
            return {loading: true, attributes: []}
        case ATTRIBUTE_LIST_SUCCESS:
            return {loading: false, success: true, attributes: action.payload}
        case ATTRIBUTE_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const questionBankListReducer = (state = {questionBanks: []}, action) => {
    switch (action.type) {
        case QUESTION_BANK_LIST_REQUEST:
            return {loading: true, questionBanks: []}
        case QUESTION_BANK_LIST_SUCCESS:
            return {loading: false, success: true, questionBanks: action.payload}
        case QUESTION_BANK_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
