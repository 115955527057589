import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchQuestionBanks} from "../../redux/actions/HomeActions";
import axios from "axios";
import DragDropHandler from "../../components/DragDropHandler";
import TableComponent from "../../components/TableComponentFiles/TableComponent";
import {getBaseUrl} from "../../App";
import SvgIcon from "../../components/IconSelector/SvgIcon";
import {showAlert} from "../../providers/AlertProvider";

const AllQuestionBanks = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchQuestionBanks())
    }, []);
    const {questionBanks, success} = useSelector((state) => state.questionBankListReducer);
    const submitArrangement = async (reorderedArray) => {
        try {
            const {data} = await axios.patch("/admin/quiz/question-bank/rearrange", reorderedArray)
            showAlert("success", "Question Banks Arranged Successfully!")
        } catch (e) {
            showAlert("error", e.response && e.response.data.detail ? e.response.data.detail : e.message)
        }
        dispatch(fetchQuestionBanks())
    }
    return (<>
        {success && <><DragDropHandler itemsArray={questionBanks} ItemComponent={ContentItemComponent}
                                       submitArrangement={submitArrangement}/>
            <TableComponent TableData={questionBanks} tableName={"Question Banks"}/></>}
    </>)
}
export default AllQuestionBanks
const ContentItemComponent = ({item}) => {
    return (<div
        className="w-full flex justify-center gap-2 items-center">
        {item.is_icon ? <SvgIcon d={item.icon_svg}/> : <img src={getBaseUrl() + item.image} className="h-10 mx-3"/>}
        <div>{item.name}</div>
    </div>)
}