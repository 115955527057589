import axios from "axios";
import {
    TITLE_LIST_ERROR,
    TITLE_LIST_REQUEST,
    TITLE_LIST_SUCCESS, ATTRIBUTE_LIST_ERROR,
    ATTRIBUTE_LIST_REQUEST,
    ATTRIBUTE_LIST_SUCCESS,
    QUESTION_BANK_LIST_ERROR,
    QUESTION_BANK_LIST_REQUEST,
    QUESTION_BANK_LIST_SUCCESS
} from "../constants/HomeConstants";

export const fetchTitles = (typeName) => async (dispatch) => {
    try {
        dispatch({type: TITLE_LIST_REQUEST})

        const {data} = await axios.get(`/admin/quiz/${typeName.toLowerCase()}-title/all`)
        dispatch({
            type: TITLE_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: TITLE_LIST_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}
export const fetchAttributes = (typeName) => async (dispatch) => {
    try {
        dispatch({type: ATTRIBUTE_LIST_REQUEST})

        const {data} = await axios.get(`/admin/quiz/${typeName.toLowerCase()}/all`)
        dispatch({
            type: ATTRIBUTE_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: ATTRIBUTE_LIST_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}
export const fetchQuestionBanks = (typeName) => async (dispatch) => {
    try {
        dispatch({type: QUESTION_BANK_LIST_REQUEST})

        const {data} = await axios.get("/general/question-bank/all?type=" + typeName)
        dispatch({
            type: QUESTION_BANK_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: QUESTION_BANK_LIST_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}