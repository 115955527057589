import React, {useEffect, useMemo, useState} from "react";
import {useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable} from "react-table"
import jsPDF from 'jspdf'
import 'jspdf-autotable';
import {utils, write, writeFile} from "xlsx";
import ColumnFilterOfTable from "./ColumnFilterOfTable";
import TablePagesComponent from "./TablePagesComponent";
import CheckboxTableComponent from "./CheckboxTableComponent";
import TableActionsComponent from "./TableActionsComponent";
import {getBaseUrl} from "../../App";
import {useSelector} from "react-redux";
import MultipleSelection from "./MultipleSelection";
import {Card} from "../ui/Card";


const TableComponent = ({TableData, tableName}) => {
    const Columns = useMemo(() => getColumns(TableData), [TableData]);
    const [searchValue, setSearchValue] = useState('')
    const [doFilter, setDoFilter] = useState(false)
    const defaultColumn = useMemo(() => {
        return {
            Filter: ColumnFilterOfTable
        }
    }, [])
    const currency = useSelector((state) => state.currencyReducer);
    const downloadPDF = () => {
        const doc = new jsPDF()
        doc.text(tableName, 14, 10)
        doc.autoTable({
            theme: "grid",
            columns: Columns.map((col) => ({...col, dataKey: col.accessor, header: col.Header})),
            body: TableData
        })
        doc.save(tableName + "_table.pdf")
    }
    const downloadExcel = () => {
        const workSheet = utils.json_to_sheet(TableData)
        const workBook = utils.book_new()
        utils.book_append_sheet(workBook, workSheet, tableName)
        //Buffer
        let buffer = write(workBook, {bookType: "xlsx", type: "buffer"})
        //Binary String
        write(workBook, {bookType: "xlsx", type: "binary"})
        //Download
        writeFile(workBook, tableName + "_table.xlsx")
    }
    // const columns = useMemo(() => Columns, [])
    const [visibleColumns, setVisibleColumns] = useState({});
    const filteredColumns = useMemo(() => {
        return Columns.filter(column => visibleColumns[column.accessor]);
    }, [Columns, visibleColumns]);
    const data = TableData
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        prepareRow,
        state,
        allColumns,
        gotoPage,
        setPageSize,
        pageCount,
        setGlobalFilter,
        selectedFlatRows,
    } = useTable({
            columns: filteredColumns, data, defaultColumn
        }, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [{
                    id: "selection",
                    Header: ({getToggleAllRowsSelectedProps}) => (
                        <CheckboxTableComponent {...getToggleAllRowsSelectedProps()} />
                    ),
                    Cell: ({row}) => (
                        <CheckboxTableComponent {...row.getToggleRowSelectedProps()} />
                    )
                }, ...columns,
                    {
                        id: "actions",
                        Header: () => (
                            "Actions"
                        ),
                        Cell: ({row}) => (
                            <TableActionsComponent id={row.allCells.find(x => x.column.Header === "Id").value}
                                                   slug={row.allCells.find(x => x.column.Header === "Slug") ? row.allCells.find(x => x.column.Header === "Slug").value : ""}
                                                   tableName={tableName}/>
                        )
                    }]
            })
        }
    )

    useEffect(() => {
        const initialVisibility = Columns.reduce((acc, column) => {
            acc[column.accessor] = true; // Set all columns to be visible initially
            return acc;
        }, {});
        setVisibleColumns(initialVisibility);
    }, [Columns]);
    const handleColumnVisibilityChange = (accessor, isVisible) => {
        setVisibleColumns(prev => ({...prev, [accessor]: isVisible}));
    };
    const getCellContent = (cell) => {
        if (cell.column.id.includes("price")) return cell.value;

        if (cell.column.id.includes("flag")) return <img src={cell.value} className="h-12" alt=""/>;
        if (cell.column.id.includes("svg")) return <div className="flex justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="40"
                 height="40" viewBox="0 -960 960 960"
                 className="text-black fill-current">
                <path d={cell.value}/>
            </svg>
        </div>;
        if (cell.column.id.includes("ated_at")) return getDate(cell.value);
        if (cell.column.id.includes("color")) return <div className="text-center rounded-lg"
                                                          style={{
                                                              backgroundColor: cell.value,
                                                              border: '1px solid black 0.4',
                                                              width: '40px', // Adjust as needed
                                                              height: '40px', // Adjust as needed
                                                              display: 'inline-block'
                                                          }}
        ></div>
            ;
        if (typeof cell.value === "object") return "Select to see";
        if (cell.value === null) return "Null";
        if (cell.value === true) return "Yes";
        if (cell.value === false) return "No";
        if (cell.column.id.includes("image")) return <img src={getBaseUrl() + cell.value} className="h-12" alt=""/>;
        return cell.render("Cell");
    }
    const isCellVisible = (cellId) =>
        !(cellId.includes('id') && !cellId.includes('slider_name')) && !cellId.includes('visibility');

    const [showFilter, setShowFilter] = useState(false)
    const {globalFilter, pageIndex, pageSize} = state
    const pageSizes = [5, 10, 25, 50, 100]
    return (
        TableData.length > 0 && <Card>
            {showFilter && <div
                className="fixed z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-2 border-black rounded-lg shadow p-5 bg-white">
                    <span onClick={() => setShowFilter(false)}
                          className="absolute top-2 right-2 material-symbols-rounded focus:outline-0 pb-1.5 cursor-pointer">close</span>
                <div className="grid grid-cols-3">
                    {Columns.map(column => (
                        <div className="m-2" key={column.accessor}>
                            <input
                                className="m-2"
                                type="checkbox"
                                id={column.accessor}
                                checked={visibleColumns[column.accessor]}
                                onChange={e => handleColumnVisibilityChange(column.accessor, e.target.checked)}
                            />
                            <label htmlFor={column.accessor}>{column.Header}</label>
                        </div>
                    ))}
                </div>
            </div>}
            <div className="flex items-center flex-col justify-between mb-3 mt-5 px-4 gap-2 w-full font-public-sans">
                <span className="text-lg font-public-sans font-bold">{tableName}</span>
                <hr/>
                <div
                    className={`flex mb-2 mx-1 w-full justify-center items-center transition-all duration-500`}>

                    <input type="text" id="searchBar"
                           className="py-2 px-3 text-md rounded-bl-md rounded-tl-md w-full border border-gray-200"
                           placeholder="Search" value={searchValue} onChange={(e) => setSearchValue(e.target.value)}/>

                    <span onClick={() => setGlobalFilter(searchValue)}
                          className="material-symbols-rounded text-md  rounded-br-md rounded-tr-md -ml-0.5 cursor-pointer border border-gray-200 font-light hover:font-normal py-2 px-4 text-gray-600 opacity-90">search</span>
                </div>
                <div className="flex justify-between w-full items-center gap-3">
                    <div
                        className="flex items-center hover:text-white hover:bg-blue-500 hover:shadow-lg shadow transition-all bg-white text-sm border-[1px] px-3 py-2 cursor-pointer border-blue-500 rounded text-blue-500 font-light"
                        onClick={downloadExcel}>
                        <span className="material-symbols-rounded mr-0.5 font-thin">lab_profile</span>
                        <span>EXCEL</span>
                    </div>
                    <div
                        className="flex items-center hover:text-white hover:bg-green-500 hover:shadow-lg shadow transition-all bg-white text-sm border-[1px] px-3 py-2 cursor-pointer border-green-500 rounded text-green-500 font-light"
                        onClick={downloadPDF}>
                        <span className="material-symbols-rounded mr-0.5 font-thin">picture_as_pdf</span>
                        <span>PDF</span>
                    </div>
                    <div onClick={() => setShowFilter(true)}
                         className="flex items-center hover:text-white hover:bg-purple-500 hover:shadow-lg shadow transition-all bg-white text-sm border-[1px] px-3 py-2 cursor-pointer border-purple-500 rounded text-purple-500 font-light">
                        <span className="material-symbols-rounded mr-0.5 font-extralight">filter_alt</span>
                        <span>Filter</span>
                    </div>
                </div>
                {selectedFlatRows.map((row) => row.original).length > 0 &&
                    <MultipleSelection tableName={tableName} selectedFlatRows={selectedFlatRows}/>}
                {doFilter && <div className="flex justify-between items-center px-2 py-2 rounded w-full my-3">
                </div>}

            </div>
            <div className="w-full overflow-x-auto px-2 py-2 font-public-sans">
                <table {...getTableProps} className={`rounded-lg w-full`}>
                    <thead className="bg-gray-100">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <>
                                    <th className={`p-4 border-b-[1px] border-black border-dashed  ${(column.id.includes('id') && !column.id.includes('slider_name')) || column.id.includes('visibility') ? "hidden" : "visible"}`}>
                                        <div className="flex justify-center items-center text-gray-700">
                                            {(column.id !== "selection" && column.id !== "actions") &&
                                                <span {...column.getHeaderProps(column.getSortByToggleProps())}
                                                      className={`material-symbols-rounded font-thin text-black text-lg ${column.isSorted ? "" : "rotate-90"}`}>{column.isSorted ? (column.isSortedDesc ? "arrow_downward" : "arrow_upward") : "sync_alt"}</span>}
                                            <span>{column.render("Header")}</span></div>
                                        <span>{column.canFilter ? column.render("Filter") : null}</span>
                                    </th>

                                </>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps}>
                    {page.map((row, rowNum) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}
                                className={`text-center ${rowNum % 2 === 0 ? "bg-white" : "bg-gray-100"}`}>
                                {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()}
                                        className={`p-2 ${isCellVisible(cell.column.id) ? "visible" : "hidden"}`}>
                                        {getCellContent(cell)}
                                    </td>
                                ))}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>

            <div className="flex flex-col items-center justify-between mx-4 mb-4 gap-2">
                <span className="flex items-center">Rows per page:
                <select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                    {pageSizes.map(x => (
                        <option>{x}</option>
                    ))}
                </select>
                    </span>
                <span>{pageSize > rows.length ? "1-" + rows.length : ((pageIndex) * pageSize + 1) + "-" + pageSize * (pageIndex + 1)} of {rows.length}</span>
                <div className="flex justify-center items-center">
                    <TablePagesComponent gotoPage={gotoPage} pageIndex={pageIndex} pageCount={pageCount}/>
                </div>
            </div>
        </Card>
    )
}

const getColumns = (data) => {
    if (data.length !== 0) {
        const keys = Object.keys(data[0])
        const columns = []
        keys.forEach((key) => {
            const header = capitalizeWords(key.replaceAll("_", " "))
            const accessor = key
            columns.push({
                Header: header,
                accessor: accessor,
                disableFilters: true
            })


        })
        return columns
    }
    return []

    function capitalizeWords(str) {
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
}
const getDate = (givenDate) => {
    const date = new Date(givenDate);
    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    };
    return date.toLocaleDateString('en-US', options)
}
export default TableComponent