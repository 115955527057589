import {combineReducers} from "redux";
import {menuItemsId, search, selectedPage, sidebar} from "./AdminPanelReducer";
import {
    authReducer,
    loginUserReducer,
    logoutUserReducer,
    refreshUserReducer,
    userDataReducer,
    usersListReducer
} from "./UserReducer";
import {attributeListReducer, questionBankListReducer, titleListReducer} from "./HomeReducer";
import {freemiumSubjectsListReducer, quizBookChapterListReducer, quizBookListReducer} from "./FreemiumReducer";

const rootReducer = combineReducers({
    menuItemsId,
    sidebar,
    search,
    selectedPage,

    titleListReducer,
    attributeListReducer,
    questionBankListReducer,

    freemiumSubjectsListReducer,
    quizBookListReducer,
    quizBookChapterListReducer,

    loginUser: loginUserReducer,
    userData: userDataReducer,
    usersList: usersListReducer,
    auth: authReducer,
    refreshUser: refreshUserReducer,
    logoutUser: logoutUserReducer,
});

export default rootReducer;