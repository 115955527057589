export const TITLE_LIST_REQUEST = "TITLE_LIST_REQUEST"
export const TITLE_LIST_SUCCESS = "TITLE_LIST_SUCCESS"
export const TITLE_LIST_ERROR = "TITLE_LIST_ERROR"

export const ATTRIBUTE_LIST_REQUEST = "ATTRIBUTE_LIST_REQUEST"
export const ATTRIBUTE_LIST_SUCCESS = "ATTRIBUTE_LIST_SUCCESS"
export const ATTRIBUTE_LIST_ERROR = "ATTRIBUTE_LIST_ERROR"

export const QUESTION_BANK_LIST_REQUEST = "QUESTION_BANK_LIST_REQUEST"
export const QUESTION_BANK_LIST_SUCCESS = "QUESTION_BANK_LIST_SUCCESS"
export const QUESTION_BANK_LIST_ERROR = "QUESTION_BANK_LIST_ERROR"