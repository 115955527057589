import React from "react";
import {useLocation} from "react-router-dom";

const getName = (pathname, page) => {
    let index = 1
    if (page) {
        index = 2
    }
    return pathname.split("/")[index].replaceAll("-", " ").split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}
const PageHeader = ({icons}) => {
    const location = useLocation();
    const pathname = location.pathname
    const selectedPage = getName(pathname, true)
    const selectedTab = getName(pathname, false)
    return (<div
        className="flex h-[3.5rem] w-full bg-white px-4 py-3 z-10  items-center  border-r-[1px] border-b-[1px] border-gray-200 shadow-lg justify-between">
        <div className="text-sm">
            {selectedPage}
        </div>
        <div className="flex items-center">
                <span
                    className="material-symbols-rounded font-light px-2 text-sm  opacity-90">{icons.find(x => x.label === selectedTab)?.name}</span>
            <div className="font-normal text-xs">{selectedTab} / {selectedPage}</div>
        </div>
    </div>)
}
export default PageHeader
