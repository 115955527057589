import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {showSidebar} from "../../redux/actions/AdminPanelActions";

import {logoutUser} from "../../redux/actions/UserActions";
import {Link, useNavigate} from "react-router-dom";

const PanelHeaderMenu = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const handleLogout = () => {
        dispatch(logoutUser())
        navigate('/')
    }
    return (
        <React.Fragment>
            <div className="sticky top-0 w-full z-20">
                <div
                    className="flex h-[4.5rem] w-full bg-white px-4 py-2 items-center border-r-[1px] border-b-[1px] border-gray-200 justify-between">
                    <div>
                            <span onClick={() => dispatch(showSidebar())}
                                  className={` material-symbols-rounded scale-y-125 cursor-pointer rounded-lg bg-black text-lg bg-opacity-10 py-0.5 px-3`}>menu</span>
                    </div>
                    <a href="https://medmolar.com" target="_blank">
                        <div
                            className="cursor-pointer flex items-center justify-center rounded-lg ml-3 px-3 py-2 bg-blue-600 text-white ">
                                        <span
                                            className="material-symbols-rounded cursor-pointer font-light hover:font-normal mr-2 ">visibility</span>
                            <span className="">Visit Site</span>
                        </div>
                    </a>
                    <div onClick={handleLogout}
                         className="cursor-pointer flex items-center justify-center rounded-lg ml-3 px-3 py-2 bg-blue-600 text-white ">
                                        <span
                                            className="material-symbols-rounded cursor-pointer font-light hover:font-normal mr-2 ">logout</span>
                        <span className="">Logout</span>
                    </div>


                </div>
            </div>

        </React.Fragment>
    )
}
export default PanelHeaderMenu