import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {fetchQuestionBanks} from "../../redux/actions/HomeActions";
import IconsSelector from "../../components/IconSelector/IconsSelector";
import ImageUploadComponent from "../../components/ImageUploadComponent";
import {Card, CardTitle} from "../../components/ui/Card";
import {Input} from "../../components/ui/Input";
import {Switch} from "../../components/ui/Switch";
import {Button} from "../../components/ui/Button";
import AddMultipleObjects from "../../components/ui/AddMultipleObjects";
import {Select} from "../../components/ui/Select";
import {axiosGet, axiosPatch, axiosPost, isBase64Image} from "../../functions/utilityFucntions";
import {getBaseUrl} from "../../App";

const UpdateQuestionBank = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    useEffect(() => {
        dispatch(fetchQuestionBanks())
    }, []);
    const initialFormData = {
        name: "",
        is_premium: true,
        is_icon: false,
        icon_name: "",
        icon_svg: "",
        image: null,
        categoryTitleId: "",
        subcategoryTitleId: "",
    }
    const [formData, setFormData] = useState(initialFormData)
    const [selectedPropertyTitles, setSelectedPropertyTitles] = useState([])

    const [categoryTitles, setCategoryTitles] = useState([])
    const [subcategoryTitles, setSubcategoryTitles] = useState([])
    const [propertyTitles, setPropertyTitles] = useState([])

    const {questionBanks, success} = useSelector((state) => state.questionBankListReducer);
    useEffect(() => {
        fetchCategoryTitle()
        fetchSubCategoryTitles()
        fetchPropertyTitles()
    }, []);
    useEffect(() => {
        if (id && success) {
            const questionBank = questionBanks.find(x => x.id === Number(id))
            setFormData({
                ...questionBank,
                categoryTitleId: questionBank.category_title,
                subcategoryTitleId: questionBank.subcategory_title
            })
            setSelectedPropertyTitles(questionBank.property_titles.reduce((acc, item) => {
                acc.push({
                    label: item.value, value: item.id
                });
                return acc;
            }, []));
        }
    }, [id, success,]);
    const url = `/admin/quiz/`
    const fetchCategoryTitle = () => {
        axiosGet(url + "category-title/all/").then((data) => {
            setCategoryTitles(data)
        })
    }
    const fetchSubCategoryTitles = () => {
        axiosGet(url + "subcategory-title/all/").then((data) => {
            setSubcategoryTitles(data)
        })
    }
    const fetchPropertyTitles = () => {
        axiosGet(url + "property-title/all/").then((data) => {
            setPropertyTitles(data)
        })
    }

    const submitQuestionBank = (e) => {
        e.preventDefault()
        formData.property_title_ids = selectedPropertyTitles.map(x => x.value)
        console.log(selectedPropertyTitles.map(x => x.value))
        if (id) {
            axiosPatch(`/admin/quiz/question-bank/update/${id}/`, formData, "Question Bank").then((data) => {
            })
        } else {
            axiosPost("/admin/quiz/question-bank/add/", formData, "Question Bank").then((data) => {
                if (data) setFormData(initialFormData)
                dispatch(fetchQuestionBanks())
            })
        }
    }
    const updateFormData = (updatedObject) => {
        setFormData((prevState) => ({...prevState, ...updatedObject}))
    }
    const setImage = (image) => {
        updateFormData({image: image})
    }

    return (<>
        <form onSubmit={submitQuestionBank}>
            <Card>
                <CardTitle>{id ? "Update" : "Add"} Question Bank</CardTitle>
                <Input type="text" id="name" value={formData.name} required={true}
                       label="Name" onChange={(e) => updateFormData({name: e.target.value})}/>
                <Switch label={formData.is_icon === false ? "Showing Image" : "Showing Icon"}
                        checked={formData.is_icon === true}
                        onChange={(e) => updateFormData({is_icon: e.target.checked})}/>
                {formData.is_icon && <IconsSelector formData={formData} setFormData={setFormData}/>}
                {!formData.is_icon && <>
                    <ImageUploadComponent required={!id} title={'Upload Image with transparent background'}
                                          setImage={setImage}/>
                    {formData.image !== null && id && !isBase64Image(formData.image) &&
                        <img src={getBaseUrl() + formData.image} className="h-20 object-contain"/>}
                </>}
                <Select type="text" id="name" value={formData.categoryTitleId} required={true}
                        label="Category Title" onChange={(e) => updateFormData({categoryTitleId: e.target.value})}>
                    <option disabled value="" selected>Select Category Title</option>
                    {categoryTitles.map((title, index) => (
                        <option key={index} value={title.id}>{title.value}</option>))}
                </Select>
                <Select type="text" id="name" value={formData.subcategoryTitleId} required={true}
                        label="SubCategory Title"
                        onChange={(e) => updateFormData({subcategoryTitleId: e.target.value})}>
                    <option disabled value="" selected>Select SubCategory Title</option>
                    {subcategoryTitles.map((title, index) => (
                        <option key={index} value={title.id}>{title.value}</option>))}
                </Select>

                <AddMultipleObjects objectsArray={propertyTitles.map(x => {
                    return {...x, name: x.value}
                })} selectedObjects={selectedPropertyTitles} label={"Add Property Titles"}
                                    setSelectedObjects={setSelectedPropertyTitles}/>
                <Switch label={formData.is_premium === false ? "Freemium" : "Premium"}
                        checked={formData.is_premium === true}
                        onChange={(e) => updateFormData({is_premium: e.target.checked})}/>
            </Card>

            <Button type="submit">{`${id ? "Update" : "Add"} Question Bank`}</Button>
        </form>
    </>)
}
export default UpdateQuestionBank
